import React from 'react';
import "./service.css";
import {BiCheck} from "react-icons/bi";

const Service = () => {
    return (
        <section id="service">
            <h5>What I Offer</h5>
            <h2>Service</h2>
            <div className="container services__container">
                <article className='service'>
                    <div className="service__head">
                        <h3>Frontend</h3>
                    </div>

                    <ul className="service__list">
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>Proficient in HTML, CSS, and JavaScript</p>
                        </li>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>Experienced in frontend frameworks such as Bootstrap, Tailwind, and React</p>
                        </li>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>Skilled in creating responsive and visually appealing web interfaces</p>
                        </li>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>Capable of leveraging the latest frontend technologies to deliver engaging user experiences</p>
                        </li>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>Proficient in translating designs from Figma to frontend web with pixel-perfect accuracy</p>
                        </li>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>Familiar with Next.js for building efficient and server-rendered React applications</p>
                        </li>
                    </ul>
                </article>

                <article className='service'>
                    <div className="service__head">
                        <h3>Backend</h3>
                    </div>

                    <ul className="service__list">
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>Proficient in Java Spring Boot for backend development</p>
                        </li>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>Experienced in leveraging Spring Boot's robust features for building scalable and efficient web applications</p>
                        </li>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>Skilled in working with MySQL databases to ensure data integrity and optimal performance in backend systems</p>
                        </li>
                    </ul>
                </article>

                <article className='service'>
                    <div className="service__head">
                        <h3>Software Development Tools</h3>
                    </div>

                    <ul className="service__list">
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>Proficient in utilizing CircleCI for continuous integration and continuous deployment (CI/CD)</p>
                        </li>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>Experienced in using GitHub for version control and collaboration in software development projects</p>
                        </li>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>Skilled in leveraging Jira for project management and issue tracking, ensuring efficient workflows and team collaboration</p>
                        </li>
                    </ul>
                </article>
            </div>
        </section>
    );
};

export default Service;