import React from 'react';
import {BsLinkedin} from "react-icons/bs";
import {FaGithub} from "react-icons/fa";
import {FiDribbble} from "react-icons/fi";


const HeaderSocial = () => {
    return (
        <div className="header__socials">
            <a href="https://www.linkedin.com/in/bach-qv-b63711252/"><BsLinkedin/></a>
            <a href="https://github.com/quachvanbach"><FaGithub/></a>
            {/* <a href="https://www.dribble.com/"><FiDribbble/></a> */}


        </div>
    );
};

export default HeaderSocial;